let subdomain = ''

const subdomainStore = {
  add: (item) => {
    subdomain = item
  },
  get: () => subdomain
}

Object.freeze(subdomainStore)
export default subdomainStore
