import React, { createContext, useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { gql } from 'apollo-boost'
import { useLazyQuery } from '@apollo/react-hooks'
import { CircularProgress, Grid } from '@material-ui/core'
import SubdomainStore from './SubdomainStore'

import PageTitle from '../components/PageTitle'

export const AccountContext = createContext()

const GET_ACCOUNT_BY_DOMAIN = gql`
  query GetAccountByDomain($domain: String!) {
    getAccountSettingsByDomain(domain: $domain) {
      id
      subdomain
      friendlyName
      description
      recaptchaSiteKey
    }
  }
`
export const AccountProvider = ({ children, account }) => {
  const [currentAccount, setAccount] = useState(account)
  const [isInit, setIsInit] = useState(!!account)
  const [getAccount, { data, error }] = useLazyQuery(GET_ACCOUNT_BY_DOMAIN)
  useEffect(() => {
    if (!currentAccount) {
      getAccount({
        variables: {
          domain: window.location.hostname
        }
      })
    }
  }, [currentAccount, getAccount])

  if (!isInit && !data) {
    return (
      <>
        <PageTitle title="Learn49" />
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{
            height: '100vh',
            background:
              'transparent linear-gradient(180deg, #0F1561 0%, #040633 100%) 0% 0% no-repeat padding-box'
          }}
        >
          <CircularProgress color="primary" />
        </Grid>
      </>
    )
  }

  if (!isInit && !currentAccount && data && data.getAccountSettingsByDomain) {
    setAccount(data.getAccountSettingsByDomain)
    SubdomainStore.add(data.getAccountSettingsByDomain.subdomain)
    setIsInit(true)
  }

  if (error) {
    return (
      <div style={{ color: '#fff', textAlign: 'center' }}>
        <h1>Account not found!</h1>
        <p>Please report this on bugs@learn49.com</p>
      </div>
    )
  }

  return (
    <AccountContext.Provider value={{ ...currentAccount }}>
      {children}
    </AccountContext.Provider>
  )
}

export const useAccount = () => {
  const currentAccount = useContext(AccountContext)
  return currentAccount
}

AccountProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
}
